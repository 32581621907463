import React, { useEffect, useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import clsx from "clsx";
import { Hero } from "@hyperobjekt/material-ui-website";
import Header from "gatsby-theme-hypersite/header/header";
import { default as Layout } from "gatsby-theme-hypersite/layout";
import {
	Box,
	Button,
	Container,
	Typography,
	Grid,
	withStyles
} from "@material-ui/core";
import Block from "gatsby-theme-hypersite/block";
import { getTimeSince, formatDate } from "../utils";
import { Content, Feed } from "../components";

const ResearchLayout = ({ classes, hero, data, ...props }) => {
	const { root, container, content, imageWrapper, image } = classes;
	const reports = data.allMdx.nodes.map(n => ({ ...n, ...n.frontmatter }));
	return (
		<Layout {...props}>
			<Block className={clsx(classes.root)}>
				{reports
					? reports.map((report, ii) => {
							return (
								<Grid container spacing={6} className={classes.report} key={ii}>
									<Grid item xs={12} sm={4} md={3}>
										<Box className={classes.thumb}>
											{report.thumb ? (
												<>
													<img src={report.thumb} alt="" />
													<Box className={classes.shadow} />
												</>
											) : (
												<Box className={classes.placeholder} />
											)}
											{report.date ? (
												<Typography variant="h6">
													{formatDate(report.date)}
												</Typography>
											) : null}
										</Box>
									</Grid>
									<Grid item xs={12} sm={8} md={4}>
										<Typography component="h2" variant="h4">
											{report.name}
										</Typography>

										{report.authors ? (
											<Typography paragraph className={classes.authors}>
												{report.authors.join(", ")}
											</Typography>
										) : null}

										{report.type === "our-work" ? (
											<Link to={`/research/${report.slug}`}>
												<Button role="presentation" variant="text">
													Read more
												</Button>
											</Link>
										) : (
											<Button
												role="presentation"
												variant="text"
												target="_blank"
												href={report.url}
												className={classes.button}
											>
												Open report
											</Button>
										)}
									</Grid>
									<Grid item xs={12} sm={12} md={5}>
										<ReactMarkdown>{report.blurb}</ReactMarkdown>
									</Grid>
								</Grid>
							);
					  })
					: ""}
			</Block>
		</Layout>
	);
};

ResearchLayout.defaultProps = {};
ResearchLayout.propTypes = {};

export default withStyles(theme => ({
	root: {
		padding: theme.spacing(6, 0),
		"& .MuiTypography-h3": {
			color: theme.palette.blue.header
		}
	},
	report: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(12),
		"& img": {
			width: "100%",
			maxWidth: theme.spacing(50)
		},
		"& .MuiTypography-h4": {
			marginBottom: theme.spacing(2)
		},
		"& .MuiGrid-item p:first-child": {
			marginTop: 0
		},
		"& .MuiButton-root": {
			marginTop: theme.spacing(3)
		},
		"& .MuiButton-text": {
			color: theme.palette.red.main
		},
		"& .MuiTouchRipple-root": {
			display: "none"
		}
	},
	authors: {
		fontSize: theme.typography.pxToRem(12)
	},
	thumb: {
		position: "relative",
		"&::before": {
			content: "''"
		},
		"& .MuiTypography-h6": {
			padding: theme.spacing(1, 2),
			position: "absolute",
			left: theme.spacing(2),
			bottom: theme.spacing(2),
			zIndex: 2,
			color: theme.palette.text.light,
			backgroundColor: theme.palette.red.main,
			borderRadius: 3,
			"&:first-child": {
				position: "static",
				display: "inline"
			}
		}
	},
	shadow: {
		width: "100%",
		height: "100%",
		position: "absolute",
		left: 0,
		bottom: 0,
		zIndex: 1,
		background: theme.palette.gradient.bottom
	},
	placeholder: {
		width: "100%",
		maxWidth: theme.spacing(50),
		paddingBottom: "100%",
		backgroundColor: theme.palette.blue.dark
	},
	button: {
		"& .MuiButton-label": {
			paddingRight: theme.spacing(0.5),
			"&::after": {
				width: theme.spacing(2),
				height: theme.spacing(2),
				position: "static",
				padding: 0,
				marginTop: 0,
				marginLeft: theme.spacing(1),
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundImage: "url(/images/icon-launch-red.svg)",
				borderWidth: 0,
				transform: "none"
			}
		}
	}
}))(ResearchLayout);

export const pageQuery = graphql`
	query ($slug: String) {
		allMdx(
			filter: {
				frontmatter: { template: { eq: "report" }, type: { eq: $slug } }
			}
			sort: { order: DESC, fields: frontmatter___date }
		) {
			nodes {
				slug
				frontmatter {
					name
					type
					thumb
					blurb
					date
					authors
					url
				}
			}
		}
	}
`;
